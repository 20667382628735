import React from 'react';
import { Route, Switch } from 'react-router';
import Dashboard from '../components/Dashboard/Dashboard';
import NoRoute from '../components/NoRoute/NoRoute';
import Login from '../components/Login/Login';
import Signup from '../components/Signup/Signup';
import auth from '../hocs/auth';
import noAuth from '../hocs/noAuth';
import CustomRoute from './CustomRoute';
import Users from '../components/Management/Users';
import ManageUser from '../components/Management/Users/ManageUser';
import ResetPassword from '../components/Management/Users/ResetPassword';
import Groups from '../components/Management/Groups';
import ManageGroup from '../components/Management/Groups/ManageGroup';
import ManageGroupUsers from '../components/Management/Groups/GroupUsers';
import ManageGroupPermissions from '../components/Management/Groups/GroupPermissions';
import * as constant from '../constants/componentConstants';
import ClientProfile from '../components/Master/ClientProfile';
import ManageClientProfile from '../components/Master/ClientProfile/ManageClientProfile';
import CostCenter from '../components/Master/CostCenter';
import ManageCostCenter from '../components/Master/CostCenter/ManageCostCenter';
import Department from '../components/Master/Department';
import ManageDepartment from '../components/Master/Department/ManageDepartment';
import ProductType from '../components/Master/ProductType';
import ManageProductType from '../components/Master/ProductType/ManageProductType';
import Location from '../components/Master/Location';
import ManageLocation from '../components/Master/Location/ManageLocation';
import AuditLog from '../components/AuditLog';
import Product from '../components/Master/Product';
import ManageProduct from '../components/Master/Product/ManageProduct';
import Requisition from '../components/Operation/Requisition';
import ManageRequisition from '../components/Operation/Requisition/ManageRequisition';
import RequisitionReport from '../components/Operation/Requisition/RequisitionReport';
import Dispatch from '../components/Operation/Dispatch';
import ManageDispatch from '../components/Operation/Dispatch/ManageDispatch';
import DispatchReport from '../components/Operation/Dispatch/DispatchReport';
import BulkDispatch from '../components/Operation/Dispatch/BulkDispatch';
import ChangePassword from '../components/Management/ChangePassword';
import CreditNote from '../components/Operation/CreditNote';
import ManageCreditNote from '../components/Operation/CreditNote/ManageCreditNote';
import CreditNoteReport from '../components/Operation/CreditNote/CreditNoteReport';
import DistributionCenter from '../components/Management/DistributionCenter';
import LocationListingRpt from '../components/Reporting/MasterListing/LocationListing';
import ProductTypeListingRpt from '../components/Reporting/MasterListing/ProductTypeListing';
import CostCenterListingRpt from '../components/Reporting/MasterListing/CostCenterListing';
import DepartmentListingRpt from '../components/Reporting/MasterListing/DepartmentListing';
import ClientProfileListingRpt from '../components/Reporting/MasterListing/ClientProfileListing';
import ProductListingRpt from '../components/Reporting/MasterListing/ProductListing';
import Company from '../components/Management/Company/index';
import ManageCompany from '../components/Management/Company/ManageCompany';
import DepartmentSummaryRpt from '../components/Reporting/OperationReport/DepartmentSummary';
import CompanyListingRpt from '../components/Reporting/AdminReport/CompanyListing';
import DepartmentSummaryCSV from '../components/Reporting/OperationReport/DepartmentSummaryCSV';
import ProductView from '../components/ProductView/ProductView';
import ProductQuantity from '../components/ProductView/ProductView/ProductQuantity';
import Orders from '../components/Orders/Orders';
import OrderReport from '../components/Orders/Orders/OrderReport';
import Customer from '../components/Customer/CustomerDetail';
import OrderDetail from '../components/Customer/OrderDetail';
import Email from '../components/Email';
import ViewCustomer from '../components/Customer/CustomerDetail/ViewCustomer';
import OrderDetailReport from '../components/Customer/OrderDetail/ViewOrder';
import EditProfile from '../components/Management/EditProfile';
import ManageDistributionCenter from '../components/Management/DistributionCenter/ManageDistributionCenter';
import ViewClient from '../components/Management/DistributionCenter/ViewClient';
import Pricing from '../components/Master/Pricing';
import UserResetPassword from '../components/ResetPassword/ResetPassword';
import EditPrice from '../components/Master/Pricing/EditPricing';
import AuditPricingLog from '../components/AuditPricingLog';
import ProductSummaryRpt from '../components/Reporting/OperationReport/ProductSummary';
import ProductSummaryCSV from '../components/Reporting/OperationReport/ProductSummaryCSV';
import PickList from '../components/Operation/PickList';
import JobReport from '../components/Operation/JobReport';
import InternalOrder from '../components/Customer/InternalOrder/InternelOrder';
import PlaceOrder from '../components/Customer/PlaceOrder';
import DispatchInternalOrder from '../components/Customer/InternalOrder/DispatchInternalOrder';
import ManageInternalOrder from '../components/Customer/InternalOrder/ManageIntenalOrder';
import InternalPickList from '../components/Operation/InternalPickList';
import ProductWiseReport from '../components/Customer/ProductWiseReport';
import ProductWiseOrderDetailReport from '../components/Customer/ProductWiseReport/ViewOrder';
import ProductWiseCustomerPrint from '../components/Customer/ProductWiseReport/ProductWiseCustomerPrint';

const routes = (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route exact path="/signup" component={Signup} />
    <Route exact path="/user/reset-password/:token" component={UserResetPassword} />
    <CustomRoute exact path="/" component={auth(Dashboard)} pageAccess={constant._general} pageAction={[constant._general]} />
    <CustomRoute exact path="/dashboard" component={auth(Dashboard)} pageAccess={constant._general} pageAction={[constant._general]} />
    <CustomRoute exact path="/management" component={auth(Users)} pageAccess={constant._general} pageAction={[constant._general]} />
    <CustomRoute exact path="/master" component={auth(ClientProfile)} pageAccess={constant._general} pageAction={[constant._general]} />
    <CustomRoute exact path="/operation" component={auth(Requisition)} pageAccess={constant._general} pageAction={[constant._general]} />
    <CustomRoute exact path="/audit" component={auth(AuditLog)} pageAccess={constant._general} pageAction={[constant._general]} />
    <CustomRoute exact path="/product-view" component={auth(ProductView)} pageAccess={constant._general} pageAction={[constant._general]} />
    <CustomRoute exact path="/order" component={auth(Orders)} pageAccess={constant._general} pageAction={[constant._general]} />
    <CustomRoute exact path="/customer" component={auth(Orders)} pageAccess={constant._general} pageAction={[constant._general]} />
    <CustomRoute exact path="/email" component={auth(Email)} pageAccess={constant._general} pageAction={[constant._general]} />
    <CustomRoute
      exact
      path="/customer/place-order"
      component={auth(PlaceOrder)}
      pageAccess={constant._distributionmanager}
      pageAction={[constant._view]}
    />
    <CustomRoute exact path="/user/reset-password" component={auth(Email)} />

    <CustomRoute
      exact
      path="/customer/customer-info"
      component={auth(Customer)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute
      exact
      path="/customer/customer-wise-orders"
      component={auth(ProductWiseCustomerPrint)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute
      exact
      path="/customer/customer-info/customer-details"
      component={auth(ViewCustomer)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute
      exact
      path="/customer/order-info"
      component={auth(OrderDetail)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute
      exact
      path="/customer/internal-order-view"
      component={auth(ManageInternalOrder)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute
      exact
      path="/customer/internal-order"
      component={auth(InternalOrder)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute
      exact
      path="/customer/product-wise-orders"
      component={auth(ProductWiseReport)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute exact path="/view-order" component={auth(InternalOrder)} pageAccess={constant._general} pageAction={[constant._general]} />
    <CustomRoute
      exact
      path="/customer/internal-order/dispatch-internal-order"
      component={auth(DispatchInternalOrder)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute
      exact
      path="/customer/order-info/admin"
      component={auth(OrderDetailReport)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute
      exact
      path="/customer/product-wise-orders/product-wise-order-info"
      component={auth(ProductWiseOrderDetailReport)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute
      exact
      path="/customer/product-wise-orders/customer-wise-print"
      component={auth(ProductWiseCustomerPrint)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute
      exact
      path="/product-view/product-list"
      component={auth(ProductView)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute
      exact
      path="/product-view/product-quantity"
      component={auth(ProductQuantity)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute exact path="/order/orderlist" component={auth(Orders)} pageAccess={constant._general} pageAction={[constant._general]} />
    <CustomRoute
      exact
      path="/order/orderlist/order-report"
      component={auth(OrderReport)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute
      exact
      path="/change-password"
      component={auth(ChangePassword)}
      pageAccess={constant._general}
      pageAction={[constant._general]}
    />
    <CustomRoute exact path="/edit-profile" component={auth(EditProfile)} pageAccess={constant._general} pageAction={[constant._general]} />
    <CustomRoute
      exact
      path="/management/companies"
      component={auth(Company)}
      pageAccess={constant._company}
      pageAction={[constant._view]}
    />
    <CustomRoute
      exact
      path="/management/companies/company"
      component={auth(ManageCompany)}
      pageAccess={constant._company}
      pageAction={[constant._create]}
    />
    <CustomRoute
      exact
      path="/management/companies/company/:companyid"
      component={auth(ManageCompany)}
      pageAccess={constant._company}
      pageAction={[constant._view, constant._create]}
    />
    <CustomRoute exact path="/management/users" component={auth(Users)} pageAccess={constant._users} pageAction={[constant._view]} />
    <CustomRoute
      exact
      path="/management/distribution-center"
      component={auth(DistributionCenter)}
      pageAccess={constant._distributioncenter}
      pageAction={[constant._view]}
    />
    <CustomRoute
      exact
      path="/management/distribution-center/add-distribution-center"
      component={auth(DistributionCenter)}
      pageAccess={constant._users}
      pageAction={[constant._view]}
    />
    <CustomRoute
      exact
      path="/management/distribution-center/edit-distribution-center/:distributionid"
      component={auth(ManageDistributionCenter)}
      pageAccess={constant._users}
      pageAction={[constant._view]}
    />
    <CustomRoute
      exact
      path="/management/distribution-center/view-client/:distributionid"
      component={auth(ViewClient)}
      pageAccess={constant._users}
      pageAction={[constant._view]}
    />
    <CustomRoute
      exact
      path="/management/users/user"
      component={auth(ManageUser)}
      pageAccess={constant._users}
      pageAction={[constant._create]}
    />

    <CustomRoute
      exact
      path="/management/distribution-center/manage-dc"
      component={auth(ManageDistributionCenter)}
      pageAccess={constant._users}
      pageAction={[constant._create]}
    />
    <CustomRoute
      exact
      path="/management/users/user/:userid"
      component={auth(ManageUser)}
      pageAccess={constant._users}
      pageAction={[constant._view, constant._create]}
    />
    <CustomRoute
      exact
      path="/management/users/reset-password/:userid"
      component={auth(ResetPassword)}
      pageAccess={constant._users}
      pageAction={[constant._view, constant._create]}
    />

    <CustomRoute exact path="/management/groups" component={auth(Groups)} pageAccess={constant._groups} pageAction={[constant._view]} />
    <CustomRoute
      exact
      path="/management/groups/group"
      component={auth(ManageGroup)}
      pageAccess={constant._groups}
      pageAction={[constant._create]}
    />
    <CustomRoute
      exact
      path="/management/groups/group/:groupid"
      component={auth(ManageGroup)}
      pageAccess={constant._groups}
      pageAction={[constant._view, constant._create]}
    />
    <CustomRoute
      exact
      path="/management/groups/group-user/:groupid"
      component={auth(ManageGroupUsers)}
      pageAccess={constant._groups}
      pageAction={[constant._view, constant._create]}
    />
    <CustomRoute
      exact
      path="/management/groups/group-permission/:groupid"
      component={auth(ManageGroupPermissions)}
      pageAccess={constant._groups}
      pageAction={[constant._view, constant._create]}
    />

    <CustomRoute
      exact
      path="/master/client-profiles"
      component={auth(ClientProfile)}
      pageAccess={constant._clientprofile}
      pageAction={[constant._view]}
    />
    <CustomRoute exact path="/master/pricing" component={auth(Pricing)} pageAccess={constant._pricing} pageAction={[constant._view]} />
    <CustomRoute
      exact
      path="/master/pricing/edit-pricing"
      component={auth(EditPrice)}
      pageAccess={constant._pricing}
      pageAction={[constant._view]}
    />
    <CustomRoute
      exact
      path="/master/client-profiles/client-profile"
      component={auth(ManageClientProfile)}
      pageAccess={constant._clientprofile}
      pageAction={[constant._create]}
    />
    <CustomRoute
      exact
      path="/master/client-profiles/client-profile/:clientprofileid"
      component={auth(ManageClientProfile)}
      pageAccess={constant._clientprofile}
      pageAction={[constant._view, constant._create]}
    />

    <CustomRoute
      exact
      path="/master/cost-centers"
      component={auth(CostCenter)}
      pageAccess={constant._costcenter}
      pageAction={[constant._view]}
    />
    <CustomRoute
      exact
      path="/master/cost-centers/cost-center"
      component={auth(ManageCostCenter)}
      pageAccess={constant._costcenter}
      pageAction={[constant._create]}
    />
    <CustomRoute
      exact
      path="/master/cost-centers/cost-center/:costcenterid"
      component={auth(ManageCostCenter)}
      pageAccess={constant._costcenter}
      pageAction={[constant._view, constant._create]}
    />

    <CustomRoute
      exact
      path="/master/departments"
      component={auth(Department)}
      pageAccess={constant._department}
      pageAction={[constant._view]}
    />
    <CustomRoute
      exact
      path="/master/departments/department"
      component={auth(ManageDepartment)}
      pageAccess={constant._department}
      pageAction={[constant._create]}
    />
    <CustomRoute
      exact
      path="/master/departments/department/:departmentid"
      component={auth(ManageDepartment)}
      pageAccess={constant._department}
      pageAction={[constant._view, constant._create]}
    />

    <CustomRoute
      exact
      path="/master/product-types"
      component={auth(ProductType)}
      pageAccess={constant._producttype}
      pageAction={[constant._view]}
    />
    <CustomRoute
      exact
      path="/master/product-types/product-type"
      component={auth(ManageProductType)}
      pageAccess={constant._producttype}
      pageAction={[constant._create]}
    />
    <CustomRoute
      exact
      path="/master/product-types/product-type/:producttypeid"
      component={auth(ManageProductType)}
      pageAccess={constant._producttype}
      pageAction={[constant._view, constant._create]}
    />

    <CustomRoute exact path="/master/locations" component={auth(Location)} pageAccess={constant._location} pageAction={[constant._view]} />
    <CustomRoute
      exact
      path="/master/locations/location"
      component={auth(ManageLocation)}
      pageAccess={constant._location}
      pageAction={[constant._create]}
    />
    <CustomRoute
      exact
      path="/master/locations/location/:locationid"
      component={auth(ManageLocation)}
      pageAccess={constant._location}
      pageAction={[constant._view, constant._create]}
    />

    <CustomRoute exact path="/master/products" component={auth(Product)} pageAccess={constant._product} pageAction={[constant._view]} />
    <CustomRoute
      exact
      path="/master/products/product"
      component={auth(ManageProduct)}
      pageAccess={constant._product}
      pageAction={[constant._create]}
    />
    <CustomRoute
      exact
      path="/master/products/product/:productid"
      component={auth(ManageProduct)}
      pageAccess={constant._product}
      pageAction={[constant._view, constant._create]}
    />

    <CustomRoute
      exact
      path="/operation/requisitions"
      component={auth(Requisition)}
      pageAccess={constant._requisition}
      pageAction={[constant._view]}
    />
    <CustomRoute
      exact
      path="/operation/requisitions/requisition"
      component={auth(ManageRequisition)}
      pageAccess={constant._requisition}
      pageAction={[constant._create]}
    />
    <CustomRoute
      exact
      path="/operation/requisitions/requisition/:requisitionid"
      component={auth(ManageRequisition)}
      pageAccess={constant._requisition}
      pageAction={[constant._view]}
    />
    <CustomRoute
      exact
      path="/operation/requisitions/requisition/:isreferred/:repeatid"
      component={auth(ManageRequisition)}
      pageAccess={constant._requisition}
      pageAction={[constant._view, constant._create]}
    />
    <CustomRoute
      exact
      path="/operation/requisitions/requisition-report/:requisitionid"
      component={auth(RequisitionReport)}
      pageAccess={constant._requisition}
      pageAction={[constant._view, constant._reportprint]}
    />

    <CustomRoute
      exact
      path="/operation/dispatches"
      component={auth(Dispatch)}
      pageAccess={constant._dispatch}
      pageAction={[constant._view]}
    />
    <CustomRoute
      exact
      path="/operation/dispatches/dispatch"
      component={auth(ManageDispatch)}
      pageAccess={constant._dispatch}
      pageAction={[constant._create]}
    />
    <CustomRoute
      exact
      path="/operation/dispatches/dispatch/:dispatchid"
      component={auth(ManageDispatch)}
      pageAccess={constant._dispatch}
      pageAction={[constant._view, constant._create]}
    />
    <CustomRoute
      exact
      path="/operation/dispatches/bulk-dispatch"
      component={auth(BulkDispatch)}
      pageAccess={constant._dispatchbulk}
      pageAction={[constant._create]}
    />
    <CustomRoute
      exact
      path="/operation/dispatches/bulk-dispatch/:clientid"
      component={auth(BulkDispatch)}
      pageAccess={constant._dispatchbulk}
      pageAction={[constant._create]}
    />
    <CustomRoute
      exact
      path="/operation/dispatches/dispatch/:isreferred/:requisitionid"
      component={auth(ManageDispatch)}
      pageAccess={constant._dispatch}
      pageAction={[constant._view, constant._create]}
    />
    <CustomRoute
      exact
      path="/operation/dispatches/dispatch-report/:dispatchid"
      component={auth(DispatchReport)}
      pageAccess={constant._dispatch}
      pageAction={[constant._view, constant._reportprint]}
    />

    <CustomRoute
      exact
      path="/operation/credit-notes"
      component={auth(CreditNote)}
      pageAccess={constant._creditnote}
      pageAction={[constant._view]}
    />
    <CustomRoute
      exact
      path="/operation/credit-notes/credit-note"
      component={auth(ManageCreditNote)}
      pageAccess={constant._creditnote}
      pageAction={[constant._create]}
    />
    <CustomRoute
      exact
      path="/operation/credit-notes/credit-note/:creditnoteid"
      component={auth(ManageCreditNote)}
      pageAccess={constant._creditnote}
      pageAction={[constant._view, constant._create]}
    />
    <CustomRoute
      exact
      path="/operation/credit-notes/credit-note-report/:creditnoteid"
      component={auth(CreditNoteReport)}
      pageAccess={constant._creditnote}
      pageAction={[constant._view, constant._reportprint]}
    />
    <CustomRoute
      exact
      path="/operation/job-report"
      component={auth(JobReport)}
      pageAccess={constant._creditnote}
      pageAction={[constant._view, constant._reportprint]}
    />
    <CustomRoute
      exact
      path="/operation/product-list"
      component={auth(PickList)}
      pageAccess={constant._creditnote}
      pageAction={[constant._view, constant._reportprint]}
    />
    <CustomRoute
      exact
      path="/operation/internal-product-list"
      component={auth(InternalPickList)}
      pageAccess={constant._creditnote}
      pageAction={[constant._view, constant._reportprint]}
    />

    <CustomRoute exact path="/audit/audit-logs" component={auth(AuditLog)} pageAccess={constant._auditlogs} pageAction={[constant._view]} />
    <CustomRoute
      exact
      path="/audit/audit-pricing-logs"
      component={auth(AuditPricingLog)}
      pageAccess={constant._auditpricinglogs}
      pageAction={[constant._view]}
    />

    <CustomRoute
      exact
      path="/reports/location-listing"
      component={auth(LocationListingRpt)}
      pageAccess={constant._reportproductlisting}
      pageAction={[constant._reportprint]}
    />
    <CustomRoute
      exact
      path="/reports/product-type-listing"
      component={auth(ProductTypeListingRpt)}
      pageAccess={constant._reportproducttypelisting}
      pageAction={[constant._reportprint]}
    />

    <CustomRoute
      exact
      path="/reports/cost-center-listing"
      component={auth(CostCenterListingRpt)}
      pageAccess={constant._reportcostcenterlisting}
      pageAction={[constant._reportprint]}
    />

    <CustomRoute
      exact
      path="/reports/department-listing"
      component={auth(DepartmentListingRpt)}
      pageAccess={constant._reportdepartmentlisting}
      pageAction={[constant._reportprint]}
    />
    <CustomRoute
      exact
      path="/reports/client-profile-listing"
      component={auth(ClientProfileListingRpt)}
      pageAccess={constant._reportclientprofilelisting}
      pageAction={[constant._reportprint]}
    />
    <CustomRoute
      exact
      path="/reports/product-listing"
      component={auth(ProductListingRpt)}
      pageAccess={constant._reportproductlisting}
      pageAction={[constant._reportprint]}
    />
    <CustomRoute
      exact
      path="/operation-reports/department-summary"
      component={auth(DepartmentSummaryRpt)}
      pageAccess={constant._reportdepartmentsummary}
      pageAction={[constant._reportprint]}
    />
    <CustomRoute
      exact
      path="/operation-reports/department-summary-csv"
      component={auth(DepartmentSummaryCSV)}
      pageAccess={constant._reportdepartmentsummary}
      pageAction={[constant._reportprint]}
    />
    <CustomRoute
      exact
      path="/operation-reports/product-summary"
      component={auth(ProductSummaryRpt)}
      pageAccess={constant._reportdepartmentsummary}
      pageAction={[constant._reportprint]}
    />
    <CustomRoute
      exact
      path="/operation-reports/product-summary-csv"
      component={auth(ProductSummaryCSV)}
      pageAccess={constant._reportdepartmentsummary}
      pageAction={[constant._reportprint]}
    />
    <CustomRoute
      exact
      path="/admin-reports/company-listing"
      component={auth(CompanyListingRpt)}
      pageAccess={constant._company}
      pageAction={[constant._adminops]}
    />

    <Route component={noAuth(NoRoute)} />
  </Switch>
);

export default routes;
