import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { Navigation } from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faUser,
  faAddressCard,
  faBook,
  faFileInvoice,
  faChartBar,
  faChartLine,
  faFileAlt,
  faVoicemail,
  faMailBulk
} from '@fortawesome/free-solid-svg-icons';
import * as permissionconst from '../../constants/componentConstants';
import { hasPermissionToAction } from '../../utils/accessUtil';
import { useSelector } from 'react-redux';

const SideBarMenu = () => {
  const ref = useRef();
  const history = useHistory();
  const location = useLocation();

  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;

  useEffect(() => {
    ref.current.recalculate();
  });
  let pathname = location.pathname;

  if (location.pathname.length > 0) {
    let splitPath = location.pathname.split('/');
    if (splitPath.length > 3) {
      pathname = '';
      for (let index = 1; index < 3; index++) {
        pathname += '/' + splitPath[index];
      }
    }
  }

  // const scrollElement = (item) => {
  //     if (item) {
  //         const currentPosition = item.offsetTop
  //         if (currentPosition > window.innerHeight) {
  //             ref.current.getScrollElement().scrollTop = currentPosition - 300
  //         }
  //     }
  // }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div className="sidebar-menu">
          <Navigation
            activeItemId={pathname}
            onSelect={({ itemId }) => {
              history.push(itemId);
            }}
            items={siderBarNavigations(permissions)}
          />
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SideBarMenu;

export const siderBarNavigations = permissions => {
  const NavItems = [
    {
      title: 'Home',
      itemId: '/',
      elemBefore: () => <FontAwesomeIcon icon={faHome} />
    }
  ];

  // Product
  if (hasPermissionToAction(permissions, permissionconst._customerproduct, permissionconst._view)) {
    let mainNavigation = {
      title: 'Product',
      itemId: '/product-view',
      elemBefore: () => <FontAwesomeIcon icon={faBook} />
    };
    let subNavigation = [];
    if (hasPermissionToAction(permissions, permissionconst._customerproduct, permissionconst._view)) {
      subNavigation.push({
        title: 'Product',
        itemId: '/product-view/product-list'
      });
    }

    if (subNavigation.length > 0) {
      mainNavigation.subNav = subNavigation;
    }
    NavItems.push(mainNavigation);
  }

  //order
  if (hasPermissionToAction(permissions, permissionconst._customerorder, permissionconst._view)) {
    let mainNavigation = {
      title: 'Order',
      itemId: '/order',
      elemBefore: () => <FontAwesomeIcon icon={faBook} />
    };
    let subNavigation = [];
    if (hasPermissionToAction(permissions, permissionconst._customerorder, permissionconst._view)) {
      subNavigation.push({
        title: 'Order',
        itemId: '/order/orderlist'
      });
    }

    if (subNavigation.length > 0) {
      mainNavigation.subNav = subNavigation;
    }
    NavItems.push(mainNavigation);
  }

  // Management
  if (
    hasPermissionToAction(permissions, permissionconst._users, permissionconst._view) ||
    hasPermissionToAction(permissions, permissionconst._groups, permissionconst._view)
  ) {
    let mainNavigation = {
      title: 'Management',
      itemId: '/management',
      elemBefore: () => <FontAwesomeIcon icon={faUser} />
    };
    let subNavigation = [];
    if (hasPermissionToAction(permissions, permissionconst._users, permissionconst._view)) {
      subNavigation.push({
        title: 'Users',
        itemId: '/management/users'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._groups, permissionconst._view)) {
      subNavigation.push({
        title: 'Groups',
        itemId: '/management/groups'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._company, permissionconst._view)) {
      subNavigation.push({
        title: 'Organizations',
        itemId: '/management/companies'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._distributioncenter, permissionconst._view)) {
      subNavigation.push({
        title: 'Remote Linen Room',
        itemId: '/management/distribution-center'
      });
    }
    if (subNavigation.length > 0) {
      mainNavigation.subNav = subNavigation;
    }
    NavItems.push(mainNavigation);
  }

  //Master
  if (hasPermissionToAction(permissions, permissionconst._clientprofile, permissionconst._view)) {
    let mainNavigation = {
      title: 'Master Details',
      itemId: '/master',
      elemBefore: () => <FontAwesomeIcon icon={faAddressCard} />
    };
    let subNavigation = [];
    if (hasPermissionToAction(permissions, permissionconst._clientprofile, permissionconst._view)) {
      subNavigation.push({
        title: `${hasPermissionToAction(permissions, permissionconst._clientadmin, permissionconst._view) ? 'Users' : 'Client Profile'}`,
        itemId: '/master/client-profiles'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._costcenter, permissionconst._view)) {
      subNavigation.push({
        title: 'Cost Center',
        itemId: '/master/cost-centers'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._department, permissionconst._view)) {
      subNavigation.push({
        title: 'Department',
        itemId: '/master/departments'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._location, permissionconst._view)) {
      subNavigation.push({
        title: 'Location',
        itemId: '/master/locations'
      });
    }
    // if (hasPermissionToAction(permissions, permissionconst._producttype, permissionconst._view)) {
    //   subNavigation.push({
    //     title: 'Product Type',
    //     itemId: '/master/product-types'
    //   });
    // }
    // if (hasPermissionToAction(permissions, permissionconst._product, permissionconst._view)) {
    //   subNavigation.push({
    //     title: 'Product',
    //     itemId: '/master/products'
    //   });
    // }
    // if (hasPermissionToAction(permissions, permissionconst._pricing, permissionconst._view)) {
    //   subNavigation.push({
    //     title: 'Pricing',
    //     itemId: '/master/pricing'
    //   });
    // }
    if (subNavigation.length > 0) {
      mainNavigation.subNav = subNavigation;
    }
    NavItems.push(mainNavigation);
  }
  if (hasPermissionToAction(permissions, permissionconst._productDetails, permissionconst._view)) {
    let mainNavigation = {
      title: 'Product Details',
      itemId: '#',
      elemBefore: () => <FontAwesomeIcon icon={faAddressCard} />
    };
    let subNavigation = [];

    if (hasPermissionToAction(permissions, permissionconst._producttype, permissionconst._view)) {
      subNavigation.push({
        title: 'Product Type',
        itemId: '/master/product-types'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._product, permissionconst._view)) {
      subNavigation.push({
        title: 'Product',
        itemId: '/master/products'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._pricing, permissionconst._view)) {
      subNavigation.push({
        title: 'Pricing',
        itemId: '/master/pricing'
      });
    }
    if (subNavigation.length > 0) {
      mainNavigation.subNav = subNavigation;
    }
    NavItems.push(mainNavigation);
  }

  //Operation
  if (hasPermissionToAction(permissions, permissionconst._requisition, permissionconst._view)) {
    let mainNavigation = {
      title: 'Operations',
      itemId: '/operation',
      elemBefore: () => <FontAwesomeIcon icon={faFileInvoice} />
    };
    let subNavigation = [];
    if (hasPermissionToAction(permissions, permissionconst._requisition, permissionconst._view)) {
      subNavigation.push({
        title: 'Requisition',
        itemId: '/operation/requisitions'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._dispatch, permissionconst._view)) {
      subNavigation.push({
        title: 'Dispatch',
        itemId: '/operation/dispatches'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._creditnote, permissionconst._view)) {
      subNavigation.push({
        title: 'Credit Note',
        itemId: '/operation/credit-notes'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._reportdepartmentsummary, permissionconst._reportprint)) {
      subNavigation.push({
        title: 'Pick List',
        itemId: '/operation/product-list'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._reportdepartmentsummary, permissionconst._reportprint)) {
      subNavigation.push({
        title: 'Job Report',
        itemId: '/operation/job-report'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._reportdepartmentsummary, permissionconst._reportprint)) {
      subNavigation.push({
        title: 'Internal Pick List',
        itemId: '/operation/internal-product-list'
      });
    }

    if (subNavigation.length > 0) {
      mainNavigation.subNav = subNavigation;
    }
    NavItems.push(mainNavigation);
  }

  //Listing reports
  if (hasPermissionToAction(permissions, permissionconst._reportclientprofilelisting, permissionconst._reportprint)) {
    let mainNavigation = {
      title: 'Reports',
      itemId: '/reports/client-profile-listing',
      elemBefore: () => <FontAwesomeIcon icon={faChartBar} />
    };
    let subNavigation = [];
    if (hasPermissionToAction(permissions, permissionconst._reportclientprofilelisting, permissionconst._reportprint)) {
      subNavigation.push({
        title: 'Client Profile',
        itemId: '/reports/client-profile-listing'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._reportcostcenterlisting, permissionconst._reportprint)) {
      subNavigation.push({
        title: 'Cost Center',
        itemId: '/reports/cost-center-listing'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._reportdepartmentlisting, permissionconst._reportprint)) {
      subNavigation.push({
        title: 'Department',
        itemId: '/reports/department-listing'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._reportlocationlisting, permissionconst._reportprint)) {
      subNavigation.push({
        title: 'Location',
        itemId: '/reports/location-listing'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._reportproducttypelisting, permissionconst._reportprint)) {
      subNavigation.push({
        title: 'Product Type',
        itemId: '/reports/product-type-listing'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._reportproductlisting, permissionconst._reportprint)) {
      subNavigation.push({
        title: 'Product',
        itemId: '/reports/product-listing'
      });
    }

    if (subNavigation.length > 0) {
      mainNavigation.subNav = subNavigation;
    }
    NavItems.push(mainNavigation);
  }

  // Operation reports

  if (hasPermissionToAction(permissions, permissionconst._reportdepartmentsummary, permissionconst._reportprint)) {
    let mainNavigation = {
      title: 'Operation Reports',
      itemId: '/operation-reports/department-summary',
      elemBefore: () => <FontAwesomeIcon icon={faChartLine} />
    };
    let subNavigation = [];
    if (hasPermissionToAction(permissions, permissionconst._reportdepartmentsummary, permissionconst._reportprint)) {
      subNavigation.push({
        title: 'Department Summary',
        itemId: '/operation-reports/department-summary'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._reportdepartmentsummary, permissionconst._reportprint)) {
      subNavigation.push({
        title: 'Department Summary CSV',
        itemId: '/operation-reports/department-summary-csv'
      });
    }
    // if (hasPermissionToAction(permissions, permissionconst._reportdepartmentsummary, permissionconst._reportprint)) {
    //   subNavigation.push({
    //     title: 'Product Summary',
    //     itemId: '/operation-reports/product-summary'
    //   });
    // }
    // if (hasPermissionToAction(permissions, permissionconst._reportdepartmentsummary, permissionconst._reportprint)) {
    //   subNavigation.push({
    //     title: 'Product Summary CSV',
    //     itemId: '/operation-reports/product-summary-csv'
    //   });
    // }

    if (subNavigation.length > 0) {
      mainNavigation.subNav = subNavigation;
    }
    NavItems.push(mainNavigation);
  }

  // Customer

  if (hasPermissionToAction(permissions, permissionconst._allcustomers, permissionconst._view)) {
    let mainNavigation = {
      title: `${
        hasPermissionToAction(permissions, permissionconst._distributionmanager, permissionconst._view)
          ? 'Order Placement'
          : hasPermissionToAction(permissions, permissionconst._clientadmin, permissionconst._view)
          ? 'Other Orders'
          : 'Customer Orders'
      }`,
      elemBefore: () => <FontAwesomeIcon icon={faChartLine} />
    };
    let subNavigation = [];
    // if (hasPermissionToAction(permissions, permissionconst._allcustomers, permissionconst._view)) {
    //   subNavigation.push({
    //     title: 'Customers',
    //     itemId: '/customer/customer-info'
    //   });
    // }
    if (hasPermissionToAction(permissions, permissionconst._allcustomerorders, permissionconst._view)) {
      subNavigation.push({
        title: 'Orders',
        itemId: '/customer/order-info'
      });
    }
    if (
      hasPermissionToAction(permissions, permissionconst._internalorder, permissionconst._view) &&
      !hasPermissionToAction(permissions, permissionconst._distributionmanager, permissionconst._view)
    ) {
      subNavigation.push({
        title: 'Internal Orders',
        itemId: '/customer/internal-order'
      });
    }
    if (
      hasPermissionToAction(permissions, permissionconst._internalorder, permissionconst._view) &&
      !hasPermissionToAction(permissions, permissionconst._distributionmanager, permissionconst._view)
    ) {
      subNavigation.push({
        title: 'Product Wise Report',
        itemId: '/customer/product-wise-orders'
      });
    }

    if (hasPermissionToAction(permissions, permissionconst._distributionmanager, permissionconst._view)) {
      subNavigation.push({
        title: 'View Orders',
        itemId: '/view-order'
      });
    }

    if (hasPermissionToAction(permissions, permissionconst._distributionmanager, permissionconst._view)) {
      subNavigation.push({
        title: 'Place Order',
        itemId: '/customer/place-order'
      });
    }

    if (subNavigation.length > 0) {
      mainNavigation.subNav = subNavigation;
    }
    NavItems.push(mainNavigation);
  }
  if (hasPermissionToAction(permissions, permissionconst._emailconfigurations, permissionconst._view)) {
    let mainNavigation = {
      title: 'Email',
      itemId: '/email',
      elemBefore: () => <FontAwesomeIcon icon={faMailBulk} />
    };
    let subNavigation = [];
    if (hasPermissionToAction(permissions, permissionconst._emailconfigurations, permissionconst._view)) {
      subNavigation.push({
        title: 'Email',
        itemId: '/email'
      });
    }

    if (subNavigation.length > 0) {
      mainNavigation.subNav = subNavigation;
    }
    NavItems.push(mainNavigation);
  }

  // Admin reports

  if (hasPermissionToAction(permissions, permissionconst._company, permissionconst._adminops)) {
    let mainNavigation = {
      title: 'Admin Reports',
      itemId: '/admin-reports/company-listing',
      elemBefore: () => <FontAwesomeIcon icon={faFileAlt} />
    };
    let subNavigation = [];
    if (hasPermissionToAction(permissions, permissionconst._company, permissionconst._adminops)) {
      subNavigation.push({
        title: 'Company',
        itemId: '/admin-reports/company-listing'
      });
    }

    if (subNavigation.length > 0) {
      mainNavigation.subNav = subNavigation;
    }
    NavItems.push(mainNavigation);
  }

  //Audit
  if (hasPermissionToAction(permissions, permissionconst._auditlogs, permissionconst._view)) {
    let mainNavigation = {
      title: 'Audit',
      itemId: '/audit',
      elemBefore: () => <FontAwesomeIcon icon={faBook} />
    };
    let subNavigation = [];
    if (hasPermissionToAction(permissions, permissionconst._auditlogs, permissionconst._view)) {
      subNavigation.push({
        title: 'Audit logs',
        itemId: '/audit/audit-logs'
      });
    }
    if (hasPermissionToAction(permissions, permissionconst._auditpricinglogs, permissionconst._view)) {
      subNavigation.push({
        title: 'Audit pricing logs',
        itemId: '/audit/audit-pricing-logs'
      });
    }

    if (subNavigation.length > 0) {
      mainNavigation.subNav = subNavigation;
    }
    NavItems.push(mainNavigation);
  }

  return NavItems;
};
