import {
  RPT_DEPT_SUMMARY_OPERATION_SET_DATA,
  RPT_DEPT_SUMMARY_OPERATION_ERROR,
  RPT_DEPT_SUMMARY_OPERATION_IS_LOADING,
  RPT_DEPT_SUMMARY_OPERATION_ON_SEARCH_CHANGE_DATA,
  RPT_DEPT_SUMMARY_OPERATION_CLEAN_UP,
  RPT_DEPT_SUMMARY_CSV_OPERATION_ERROR,
  RPT_DEPT_SUMMARY_CSV_OPERATION_IS_LOADING,
  RPT_DEPT_SUMMARY_OPERATION_CSV_IS_LOADING,
  RPT_DEPT_SUMMARY_OPERATION_SET_DATA_CSV,
  RPT_DEPT_SUMMARY_OPERATION_SET_DATA_CSV_DEP,
  RPT_DEPT_SUMMARY_OPERATION_SET_DATA_INTERNAL,
  CUSTOMER_JOB_REPORT_GET_RESULT,
  CUSTOMER_JOB_REPORT_IS_LOADING,
  CUSTOMER_JOB_REPORT_ERROR
} from '../../../constants/index';
import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults, setOnSearchChangeData, setOnCleanUpData, generateCSV } from '../actionHelper';
import moment from 'moment';
import FileSaver from 'file-saver';

const API_URL = '/operationreport';

export const getAllRptProdSummaryData = (filterBy, sortBy, includeFilters = false) => dispatch => {
  dispatch(_getAllRptProdSummaryData(filterBy, sortBy, includeFilters));
};

const _getAllRptProdSummaryData = (filterBy, sortBy, includeFilters) => {
  // debugger
  return apiHelper({
    url: `${API_URL}/productdispatch`,
    method: 'POST',
    data: {
      filterBy: filterBy,
      sortBy: sortBy,
      includeFilters: includeFilters
    },
    onLoad: status => setLoadingStatus(RPT_DEPT_SUMMARY_OPERATION_IS_LOADING, status),
    onSuccess: result => setGetResults(RPT_DEPT_SUMMARY_OPERATION_SET_DATA, result),
    // onFailure: err => setErrorThunk(RPT_DEPT_SUMMARY_OPERATION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};

export const getAllProductPickList = (filterBy, sortBy, includeFilters = false) => dispatch => {
  dispatch(_getAllProductPickList(filterBy, sortBy, includeFilters));
};

const _getAllProductPickList = (filterBy, sortBy, includeFilters) => {
  return apiHelper({
    url: `${API_URL}/picklist`,
    method: 'POST',
    data: {
      filterBy: filterBy,
      // sortBy: sortBy,
      includeFilters: includeFilters
    },
    onLoad: status => setLoadingStatus(RPT_DEPT_SUMMARY_OPERATION_IS_LOADING, status),
    onSuccess: result => setGetResults(RPT_DEPT_SUMMARY_OPERATION_SET_DATA, result),
    // onFailure: err => setErrorThunk(RPT_DEPT_SUMMARY_OPERATION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
export const getAllProductInternalPickList = (filterBy, sortBy, includeFilters = false) => dispatch => {
  dispatch(_getAllProductInternalPickList(filterBy, sortBy, includeFilters));
};

const _getAllProductInternalPickList = (filterBy, sortBy, includeFilters) => {
  return apiHelper({
    url: `/operationreport/picklistInternal`,
    method: 'POST',
    data: {
      filterBy: filterBy,
      sortBy: sortBy,
      includeFilters: includeFilters
    },
    onLoad: status => setLoadingStatus(RPT_DEPT_SUMMARY_OPERATION_IS_LOADING, status),
    onSuccess: result => setGetResults(RPT_DEPT_SUMMARY_OPERATION_SET_DATA_INTERNAL, result),
    // onFailure: err => setErrorThunk(RPT_DEPT_SUMMARY_OPERATION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
export const getAllRptProdSummaryCSVData = (filterBy, sortBy, isordered, isbilled, includeFilters = false) => dispatch => {
  // debugger
  dispatch(_getAllRptProdSummaryCSVData(filterBy, sortBy, isordered, isbilled, includeFilters));
};

const _getAllRptProdSummaryCSVData = (filterBy, sortBy, isbilled, isordered, includeFilters) =>
  apiHelper({
    url: `${API_URL}/productdispatchcsv`,
    method: 'POST',
    data: {
      filterBy: filterBy,
      sortBy: sortBy,
      isOrdered: isordered, // Add isordered to the data payload
      isBilled: isbilled,
      includeFilters: includeFilters
    },
    onLoad: status => setLoadingStatus(RPT_DEPT_SUMMARY_OPERATION_IS_LOADING, status),
    onSuccess: result => setGetResults(RPT_DEPT_SUMMARY_OPERATION_SET_DATA_CSV, result),
    // onFailure: err => setErrorThunk(RPT_DEPT_SUMMARY_OPERATION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const getAllRptDeptSummaryData = (filterBy, sortBy, includeFilters = false) => dispatch => {
  // if (filterBy.length > 0) {
  //     filterBy.forEach(item => {
  //         if (item.name === "fromdate" && item.value != null) {
  //           item.value = moment.utc(item.value).format("DD-MM-YYYY HH:mm:ss")
  //         }
  //         if (item.name === "todate" && item.value != null) {
  //           item.value = moment.utc(item.value).format("DD-MM-YYYY HH:mm:ss")
  //         }
  //     });
  // }

  dispatch(_getAllRptDeptSummaryData(filterBy, sortBy, includeFilters));
};

const _getAllRptDeptSummaryData = (filterBy, sortBy, includeFilters) =>
  apiHelper({
    url: `${API_URL}/departmentsummary`,
    method: 'POST',
    data: {
      filterBy: filterBy,
      sortBy: sortBy,
      includeFilters: includeFilters
    },
    onLoad: status => setLoadingStatus(RPT_DEPT_SUMMARY_OPERATION_IS_LOADING, status),
    onSuccess: result => setGetResults(RPT_DEPT_SUMMARY_OPERATION_SET_DATA, result),
    onFailure: err => setErrorThunk(RPT_DEPT_SUMMARY_OPERATION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const setOnSearchChange = retData => dispatch => {
  dispatch(setOnSearchChangeData(RPT_DEPT_SUMMARY_OPERATION_ON_SEARCH_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
  dispatch(setOnCleanUpData(RPT_DEPT_SUMMARY_OPERATION_CLEAN_UP, retData));
};

export const getCSVReport = (StartDate, EndDate, ClientProfileId) => dispatch => {
  dispatch(
    apiHelper({
      url: `${API_URL}/generatecsvreport`,
      method: 'POST',
      data: {
        StartDate,
        EndDate,
        ClientProfileId
      },
      onSuccess: data => setGetResults(RPT_DEPT_SUMMARY_OPERATION_SET_DATA_CSV_DEP, data),
      onLoad: status => setLoadingStatus(RPT_DEPT_SUMMARY_OPERATION_CSV_IS_LOADING, status),
      onFailure: err => setErrorThunk(RPT_DEPT_SUMMARY_CSV_OPERATION_IS_LOADING, true, { errorMessage: 'Something Went Wrong' }),
      accessToken: Cookies.get('accessToken')
    })
  );
};

export const sendJobReport = (startDate, EndDate) => dispatch => {
  dispatch(_sendJobReport(startDate, EndDate));
};

const _sendJobReport = (startDate, EndDate) => {
  // debugger
  console.log('api call');
  return apiHelper({
    url: `/customer/reportjob`,
    method: 'POST',
    data: {
      startDate: startDate,
      endDate: EndDate
    },
    onLoad: status => setLoadingStatus(CUSTOMER_JOB_REPORT_IS_LOADING, status),
    onSuccess: result => setGetResults(CUSTOMER_JOB_REPORT_GET_RESULT, result),
    onFailure: err => setErrorThunk(CUSTOMER_JOB_REPORT_ERROR, true, { errorMessage: 'Something Went Wrong' }),
    accessToken: Cookies.get('accessToken')
  });
};
