import {
  RPT_DEPT_SUMMARY_OPERATION_SET_DATA,
  RPT_DEPT_SUMMARY_OPERATION_ERROR,
  RPT_DEPT_SUMMARY_OPERATION_IS_LOADING,
  RPT_DEPT_SUMMARY_OPERATION_ON_SEARCH_CHANGE_DATA,
  RPT_DEPT_SUMMARY_OPERATION_CLEAN_UP, RPT_DEPT_SUMMARY_OPERATION_CSV_IS_LOADING, RPT_DEPT_SUMMARY_OPERATION_SET_DATA_CSV, RPT_DEPT_SUMMARY_OPERATION_SET_DATA_CSV_DEP, RPT_DEPT_SUMMARY_OPERATION_SET_DATA_INTERNAL
} from '../../../constants/index';
import { initialRptDeptSummaryState } from '../initialReportState';
import Papa from 'papaparse';
const rptDeptSummaryReducer = (state = initialRptDeptSummaryState, action) => {
  const { payload, type } = action;

  switch (type) {
    case RPT_DEPT_SUMMARY_OPERATION_ERROR: {
      const { showMessage, errorMessage } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage
      };
    }
    case RPT_DEPT_SUMMARY_OPERATION_SET_DATA: {
      const { resultData } = payload;

      let filterDataResult = state.filterData;

      if (resultData.filterData) {
        filterDataResult = resultData.filterData;
      }

      return {
        ...state,
        rptList: resultData.reportData,
        filterData: filterDataResult
      };
    }
    case RPT_DEPT_SUMMARY_OPERATION_SET_DATA_INTERNAL: {
      const { resultData } = payload;

      let filterDataResult = state.filterData;

      if (resultData.filterData) {
        filterDataResult = resultData.filterData;
      }

      return {
        ...state,
        rptListInternal: resultData.reportData,
        filterData: filterDataResult
      };
    }
    case RPT_DEPT_SUMMARY_OPERATION_SET_DATA_CSV: {
      const { resultData } = payload;
      
      // const parsedData = Papa.parse(resultData, { header: true }).data
      return {
        ...state,
        csvData: resultData.reportData
      };
    }
    case RPT_DEPT_SUMMARY_OPERATION_SET_DATA_CSV_DEP: {
      const { resultData } = payload;
      const parsedData = Papa.parse(resultData, { header: true }).data
      return {
        ...state,
        csvDataDep: parsedData
      };
    }
    case RPT_DEPT_SUMMARY_OPERATION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }

    case RPT_DEPT_SUMMARY_OPERATION_CLEAN_UP: {
      let { data } = payload;
      return {
        ...state,
        ...data 
      };
    }

    case RPT_DEPT_SUMMARY_OPERATION_ON_SEARCH_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          ...data
        }
      };
    }
    case RPT_DEPT_SUMMARY_OPERATION_CSV_IS_LOADING: {
        const { isLoading } = payload;
        return {
          ...state,
          isLoading: isLoading
        };
      }
    default:
      return state;
  }
};

export default rptDeptSummaryReducer;
